export const encodeLocationId = (locationId: number): string => {
    let base36 = locationId.toString(36).toUpperCase();

    while (base36.length < 5) {
        base36 = "0" + base36;
    }

    return base36;
};

export const decodeLocationId = (encoded: string): number => {
    const trimmed = encoded.replace(/^0+/, "");

    const decodedNumber = parseInt(trimmed, 36);

    return decodedNumber;
};
