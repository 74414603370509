import axios from "axios";

export const sendPrinterImage = async (
  printerName: string,
  filePath: string,
  locationCode: string
) => {
  const { data } = await axios.post(
    process.env.REACT_APP_PRINTER_ENDPOINT + "/sendPrintJob",
    {
      filePath,
    },
    {
      headers: {
        "x-functions-key":
          "3ukriAXUTQlvcmqqUBn2aVyEefGGsKe_3eZt9mGkuhJuAzFusXdmMg==",
        filename: "badge.png",
      },
      params: {
        printerName,
        locationCode,
      },
    }
  );
  return data.time;
};
