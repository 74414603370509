import axios from "../config/axios";
import { getFirebaseToken } from "./app";

export const getStationData = async (stationCode: string) => {
  try {
    const cacheStationData = localStorage.getItem("stationDataServer");

    const { data } = await axios.get(`/station/code/${stationCode}`, {
      params: {
        isForceUpdate: !cacheStationData,
      },
    });

    if (data.isLoadFromCache) {
      return JSON.parse(cacheStationData || "{}");
    } else {
      try {
        localStorage.setItem("stationDataServer", JSON.stringify(data));
      } catch (error) {
        console.log(error);

        return data;
      }

      return data;
    }
  } catch (error: any) {
    if (error.response) return error.response.data;

    console.log(error);
  }
};

export const getStationByDwelling = async (id: number): Promise<any> => {
  try {
    const response = await axios.get(`/station/terms/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const linkStation = async (stationCode: string): Promise<any> => {
  try {
    const response = await axios.post(`/station`, {
      stationCode,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const sendFCMNotification = async (
  tokens: string[],
  body: any,
  title: string
) => {
  try {
    const access_token = await getFirebaseToken();

    for (let i = 0; i < tokens.length; i++) {
      await axios.post(
        "https://fcm.googleapis.com/v1/projects/virtual-front-desk/messages:send",
        {
          message: {
            token: tokens[i],
            notification: {
              body: body,
              title: title,
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    }

    return {
      status: 200,
    };
  } catch (error) {
    return {
      status: 400,
    };
  }
};

export const sendSMS = async (smsArr: string[], body: string) => {
  try {
    for (let i = 0; i < smsArr.length; i++) {
      try {
        const resp = await axios.post("/acs/sms", {
          to:
            smsArr[i].replace("|", "")[0] === "+"
              ? smsArr[i].replace("|", "")
              : `+1${smsArr[i].replace("|", "")}`,
          message: `${body}`,
        });

        if (resp.status !== 200) throw new Error("Error sending SMS");
      } catch (error) {
        await axios.post("/acs/sms", {
          to:
            smsArr[i].replace("|", "")[0] === "+"
              ? smsArr[i].replace("|", "")
              : `+${smsArr[i].replace("|", "")}`,
          message: `${body}`,
        });
      }
    }

    return {
      status: 200,
    };
  } catch (error) {
    return {
      status: 400,
    };
  }
};

export const addStay = async (hotelId: number, data: any) => {
  try {
    const response = await axios.post(`/stay/${hotelId}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAcsAuth = async (stationCode: string) => {
  try {
    const response = await axios.post(`/acs/azureauth`, {
      stationCode,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAcsToken = async () => {
  try {
    const response = await axios.get("/acs");
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const editStay = async (data: any) => {
  try {
    const response = await axios.put(`/stay/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addStayScreenshot = async (stayId: number, data: any) => {
  try {
    const response = await axios.post(`/stay/screenshot/${stayId}`, data);

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getTwilioVoiceToken = async () => {
  try {
    const response = await axios.get("/acs/voicetoken");
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDepNamesById = async (depIds: number[]) => {
  try {
    const response = await axios.post(`/department/nameByIds`, {
      depIds: depIds,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addVisitorSignInData = async (
  stationId: number,
  visitorSignInFlowId: number,
  answerJson: string,
  signatureImage?: {
    imageData: string;
    imageType: string;
  },
  selfieImage?: {
    imageData: string;
    imageType: string;
  }
) => {
  try {
    const response = await axios.post(`/station/visitorSignIn`, {
      stationId,
      visitorSignInFlowId,
      answerJson,
      signatureImage,
      selfieImage,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDocumentBufferData = async (path: string) => {
  try {
    const response = await axios.post(`/document/getByPath`, {
      path,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const sendEmailNotification = async (
  email: string,
  stationName: string,
  buttonName: string,
  ownerId: number
) => {
  try {
    const response = await axios.post(`/station/sendEmail`, {
      email,
      stationName,
      buttonName,
      ownerId,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const visitorCheckOut = async (visitorSignInId: number) => {
  try {
    const response = await axios.get(
      `/visitorSignIn/signOut/${visitorSignInId}`
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getEmployeesById = async (userIds: number[]) => {
  try {
    const response = await axios.post(`/user/usersByIds`, {
      userIds,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
